var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:_vm.isExpanded && 'is-expanded'},[_c('b-modal',{attrs:{"id":"modal-g2-review","centered":"","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"card-body text-center"},[_c('img',{staticStyle:{"width":"10rem"},attrs:{"src":require("@/assets/icons/g2.png")}})]),_c('div',{staticClass:"card-text mt-2 text-center"},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("multilingual")(_vm.Tell_Us_What_You_Think))+" ")])]),_c('div',{staticClass:"card-text mt-2 text-center"},[_c('p',[_vm._v(" "+_vm._s(_vm._f("multilingual")(_vm.We_d_love_for_your_review_us_on_G2))+" "+_vm._s(_vm._f("multilingual")(_vm.To_continue_your_email_address_will_be_shared_with_G2))+" "),_c('a',{attrs:{"href":"https://legal.g2.com/privacy-policy","target":"_blank"}},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Privacy_Policy)))]),_vm._v(" "+_vm._s(_vm._f("multilingual")(_vm.and))+" "),_c('a',{attrs:{"href":"https://legal.g2.com/terms-of-use","target":"_blank"}},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Terms_of_Use)))]),_vm._v(". ")])]),_c('div',{staticClass:"card-text mt-2 text-center"},[_c('p',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(_vm._f("multilingual")(_vm.Approximate_time_of_completion_less_than_10_minutes))+" ")])]),_c('div',{staticClass:"card-text mt-2 text-center"},[_c('button',{staticClass:"btn-primary w-100 text-center py-2 px-5 rounded",on:{"click":_vm.forwardToReview}},[_c('b',[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Review_us_on_G2)))])])])]),_vm._m(0),_c('div',{staticClass:"sidebar-fade d-none d-lg-flex"}),_c('button',{staticClass:"ms-auto mt-3 me-2 d-block d-lg-none",on:{"click":function($event){return _vm.toggleMenu(false)}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" clear ")])]),_c('div',{staticClass:"menu-wrapper"},[_c('div',{staticClass:"menu d-flex"},[_c('div',{staticClass:"mb-auto sidebar-content"},[_c('router-link',{class:[
            _vm.$route.fullPath.includes('dashboard')
              ? 'button active'
              : 'button',
          ],attrs:{"to":"/dashboard"}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("space_dashboard")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Dashboard)))])]),(!_vm.$route.fullPath.includes('settings'))?_c('router-link',{class:[
            _vm.$route.fullPath.includes('projects') ? 'button active' : 'button',
          ],attrs:{"to":{
            name: 'project_pagination_table',
            params: { feature: 'projects' },
          }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("folder_open")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.All_Projects)))])]):_vm._e(),(
            _vm.showSeo &&
            !_vm.$route.fullPath.includes('settings') &&
            !_vm.$route.fullPath.includes('enterprise_seo/campaign')
          )?_c('div',{staticClass:"menu-collapsible"},[_c('button',{staticClass:"button-collapsible",attrs:{"type":"button","data-toggle":"collapse","data-target":"#collapse1","aria-expanded":"true","aria-controls":"collapse1"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.AI_SEO)))]),_c('span',{staticClass:"material-icons dot-icon"},[_vm._v("fiber_manual_record")]),_c('span',{staticClass:"material-icons-outlined arrow-icon"},[_vm._v("expand_more")])]),_c('div',{staticClass:"menu-collapse collapse show",attrs:{"id":"collapse1"}},[(
                this.permissions.includes('keyword_query.index') &&
                _vm.userInfo.free_keyword_query_quota +
                  _vm.userInfo.used_keyword_query_quota >
                  0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('keyword_discovery')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'keyword_discovery' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("screen_search_desktop")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Keyword_Discovery)))])]):_vm._e(),(
                this.permissions.includes('nexrank-clients.keywords.index') &&
                _vm.userInfo.keywords_qouta > 0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('keyword_tracker')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'project_pagination_table',
                params: { feature: 'keyword_tracker' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("query_stats")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Keyword_Tracker)))])]):_vm._e(),(
                this.permissions.includes('Seo-Automation.index') &&
                _vm.userInfo.free_nex_automate_quota +
                  _vm.userInfo.used_nex_automate_quota >
                  0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('ai_seo_automation')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'ai_seo_automation' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("psychology")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.AI_SEO_Automation)))])]):_vm._e(),(
                this.permissions.includes('Seo-Automation.index') &&
                _vm.userInfo.free_content_planner_quota +
                  _vm.userInfo.used_content_planner_quota >
                  0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('ai_content_planner')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'ai_content_planner' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("fact_check")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.AI_Content_Planner)))])]):_vm._e(),(
                this.permissions.includes(
                  'Clients.Recommendation-Campaign.index'
                ) &&
                _vm.userInfo.keywords_qouta > 0 &&
                _vm.email != 'maxis.grow@gmail.com'
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('meta_cms')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'project_pagination_table',
                params: { feature: 'meta_cms' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("terminal")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.CMS_Editor)))])]):_vm._e(),(
                this.permissions.includes('indexability.index') &&
                _vm.userInfo.used_indexability_quota +
                  _vm.userInfo.free_indexability_quota >
                  0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('enterprise_seo')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'enterprise_seo' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("display_settings")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Enterprise_SEO)))])]):_vm._e()],1)]):_vm._e(),(
            _vm.showContent &&
            !_vm.$route.fullPath.includes('settings') &&
            !_vm.$route.fullPath.includes('enterprise_seo/campaign')
          )?_c('div',{staticClass:"menu-collapsible"},[_c('button',{staticClass:"button-collapsible",attrs:{"type":"button","data-toggle":"collapse","data-target":"#collapse2","aria-expanded":"true","aria-controls":"collapse2"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.AI_Content_Generation)))]),_c('span',{staticClass:"material-icons dot-icon"},[_vm._v("fiber_manual_record")]),_c('span',{staticClass:"material-icons-outlined arrow-icon"},[_vm._v("expand_more")])]),_c('div',{staticClass:"menu-collapse collapse show",attrs:{"id":"collapse2"}},[(
                this.permissions.includes('Nexwriter.index') &&
                _vm.userInfo.nexwriter_quota > 0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('content_image')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'content_image' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("history_edu")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.AI_Content_Generation)))])]):_vm._e(),(
                this.permissions.includes('nexart.index') &&
                _vm.userInfo.used_nexart_quota + _vm.userInfo.free_nexart_quota > 0
              )?_c('router-link',{class:[
                _vm.$route.fullPath.includes('image_generator')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'image_generator' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("image")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.AI_Image_Generator)))])]):_vm._e(),(this.permissions.includes('NexSocial.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('social_generator')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'social_generator' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("send_to_mobile")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Text2Social)))])]):_vm._e(),(_vm.permissions.includes('NexIntelligence.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('nex_intelligence')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'feature_pagination_table',
                params: { feature: 'nex_intelligence' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("manage_search")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s("NexIntelligence"))])]):_vm._e()],1)]):_vm._e(),(
            !_vm.$route.fullPath.includes('settings') &&
            !_vm.$route.fullPath.includes('enterprise_seo/campaign')
          )?_c('div',{staticClass:"menu-collapsible"},[_c('button',{staticClass:"button-collapsible",attrs:{"type":"button","data-toggle":"collapse","data-target":"#collapse4","aria-expanded":"true","aria-controls":"collapse4"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Help)))]),_c('span',{staticClass:"material-icons dot-icon"},[_vm._v("fiber_manual_record")]),_c('span',{staticClass:"material-icons-outlined arrow-icon"},[_vm._v("expand_more")])]),_c('div',{staticClass:"menu-collapse collapse show",attrs:{"id":"collapse4"}},[_c('router-link',{class:[
                _vm.$route.fullPath.includes('tutorials')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":"/tutorials"}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("ondemand_video")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Tutorial)))])])],1)]):_vm._e(),(_vm.$route.fullPath.includes('settings'))?_c('div',{staticClass:"menu-collapsible"},[_c('button',{staticClass:"button-collapsible",attrs:{"type":"button","data-toggle":"collapse","data-target":"#collapse1","aria-expanded":"true","aria-controls":"collapse1"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Settings)))]),_c('span',{staticClass:"material-icons dot-icon"},[_vm._v("fiber_manual_record")]),_c('span',{staticClass:"material-icons-outlined arrow-icon"},[_vm._v("expand_more")])]),_c('div',{staticClass:"menu-collapse collapse show",attrs:{"id":"collapse1"}},[_c('router-link',{class:[
                _vm.$route.fullPath.includes('profile')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'settings',
                params: { feature: 'profile' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("manage_accounts")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Profile)))])]),(this.permissions.includes('employees.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('employees')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'settings',
                params: { feature: 'employees' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("people_alt")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Employees)))])]):_vm._e(),(this.permissions.includes('Saas-Client.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('my_clients')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'settings',
                params: { feature: 'my_clients' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("supervised_user_circle")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Clients)))])]):_vm._e(),(this.permissions.includes('Saas-Client.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('saas_clients')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'settings',
                params: { feature: 'saas_clients' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("supervisor_account")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.SaaS_Clients)))])]):_vm._e(),(this.permissions.includes('roles.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('roles')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'settings',
                params: { feature: 'roles' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("fingerprint")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Roles)))])]):_vm._e(),(this.permissions.includes('policies.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('policies')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'settings',
                params: { feature: 'policies' },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("admin_panel_settings")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Policies)))])]):_vm._e()],1)]):_vm._e(),(_vm.$route.fullPath.includes('enterprise_seo/campaign'))?_c('div',{staticClass:"menu-collapsible"},[_c('button',{staticClass:"button-collapsible",attrs:{"type":"button","data-toggle":"collapse","data-target":"#collapse1","aria-expanded":"true","aria-controls":"collapse1"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")("Enterprise SEO")))]),_c('span',{staticClass:"material-icons dot-icon"},[_vm._v("fiber_manual_record")]),_c('span',{staticClass:"material-icons-outlined arrow-icon"},[_vm._v("expand_more")])]),_c('div',{staticClass:"menu-collapse collapse show",attrs:{"id":"collapse1"}},[(this.permissions.includes('indexability.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('enterprise_seo/campaign') &&
                !_vm.$route.fullPath.includes('pending_tasks/all-tasks') &&
                !_vm.$route.fullPath.includes(
                  'page_analysis/enterprise_seo_page_analysis'
                ) &&
                !_vm.$route.fullPath.includes('page_speed_insight')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'enterprise_seo',
                params: { id: _vm.$route.params.id },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("display_settings")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")("Dashboard")))])]):_vm._e(),(this.permissions.includes('indexability.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes('pending_tasks/all-tasks')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'enterprise_seo_allTasks',
                params: { id: _vm.$route.params.id },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("task")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")("All Tasks")))])]):_vm._e(),(this.permissions.includes('PageSpeed.index'))?_c('router-link',{class:[
                _vm.$route.fullPath.includes(
                  'page_analysis/enterprise_seo_page_analysis'
                )
                  ? 'button active'
                  : _vm.$route.fullPath.includes('page_speed_insight')
                  ? 'button active'
                  : 'button',
              ],attrs:{"to":{
                name: 'enterprise_seo_page_analysis',
                params: { id: _vm.$route.params.id },
              }}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("speed")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("multilingual")("Page Speed Insight")))])]):_vm._e()],1)]):_vm._e()],1),_c('div',{staticClass:"sidebar-bottom-overlay px-3 w-100 bg-white"},[(_vm.showRemain)?_c('div',{staticClass:"credit__box"},[_c('span',{staticClass:"text-primary fw-600"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.Remaining_Credit)))]),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm._f("multilingual")(_vm.feature)))]),_c('div',{staticClass:"bg-primary rounded py-1 mt-1"},[_c('span',{staticClass:"text-white fw-600"},[_vm._v(_vm._s(_vm.quota_remain)+" / "+_vm._s(_vm.full_quota))])])]):_vm._e(),_c('div',{staticClass:"buttons__mobile-sidebar d-flex d-lg-none"},[_c('div',{staticClass:"ms-auto"}),_c('button',{staticClass:"d-flex align-items-center",on:{"click":_vm.profile}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("person")])]),_c('button',{staticClass:"d-flex align-items-center",on:{"click":_vm.settings}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("settings")])]),_c('button',{staticClass:"d-flex align-items-center pe-0",on:{"click":_vm.logout}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("logout")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-logo d-none d-lg-flex"},[_c('img',{attrs:{"src":require("../assets/nexmind/nexmind-logo.png"),"alt":"Nexmind"}}),_c('span',{staticClass:"sidebar-logo__text"},[_c('strong',[_vm._v("Nex"),_c('span',{staticStyle:{"color":"#333333"}},[_vm._v("Mind")])])])])
}]

export { render, staticRenderFns }